<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="companies">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth && platformNoAuth" class="addBtn" @click="$refs.editPage.open(0, 3)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <el-table-column prop="no" :label="$t('company.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="name" :label="$t('company.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="abbreviation" :label="$t('company.abbreviation')" width="120"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevCount" :label="$t('company.elevCount')" align="center" width="100"></el-table-column>
      <el-table-column prop="userCount" :label="$t('company.userCount')" align="center" width="100"></el-table-column>
      <el-table-column :label="$t('company.type')" :width="$l('company.width', '100')" align="center">
        <template slot-scope="scope">
          {{ getTypeName(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column v-if="showOrgId" prop="orgName" label="所属单位" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="showOrgId" prop="tenantName" label="租户" show-overflow-tooltip></el-table-column>
      <el-table-column prop="creator" label="创建人" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" width="140"></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="150" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="addUserAuth" type="text" @click="$refs.addUser.open(scope.row.id, scope.row.name)">{{
            $l("company.addUser", "新增用户") }}</el-button>
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
          }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <add-user ref="addUser"></add-user>
    <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
  </div>
</template>
<script>
import { companyType, companyType_en } from "@/util/constant";
import EditDialog from "@/views/company/CommonEdit";
import auth from "@/util/auth";
import AddUser from "@/views/company/CompanyAddUser";

const moduleName = "companies";

export default {
  components: { EditDialog, AddUser },
  data() {
    return {
      addUserAuth: this.$auth(2),
      editAuth: this.$auth(11),
      platformNoAuth: auth.getCurrentOrgId() != 0 || auth.getUsername() === "admin" || auth.getUsername() === "superAdmin",
      showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
      loading: false,
      search: {
        filter: "",
        type: "3",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    getTypeName(type) {
      if (this.$i18n.isCn) {
        return companyType[type];
      } else {
        return companyType_en[type];
      }
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id, row.type);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("company.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
